.page {
  flex-grow: 1;
}
.paddingTop {
  padding-top: 20px;
}
.paddingSides {
  padding-left: 40px;
  padding-right: 40px;
}
.formTitle {
  padding-bottom: 30px;
}
.fieldContainer {
  padding-top: 10px;
  padding-bottom: 20px;
}
.buttonsContainer {
  margin-top: 50px;
  gap: 30px;
  /*margin-bottom: 20px;*/
}
.cancelLink {
  color: var(--new-feedback-inactive);
  font-weight: bold;
}
