.userName {
    font-weight: bold;
}
.deleteRoundDialogRoot {
    padding: 10px;
    border-radius: 8px !important;
}

.title {
    padding-bottom: 10px;
}