.filterMultiselectModalRoot {
    border-radius: 8px !important;
    padding: 10px;
    min-width: 90%;
}
.itemContainer {
    border-radius: 8px !important;
    margin: 5px;
    padding: 5px;
    border: 1px solid yellow;
}
.noTopMargin {
    margin: 0;
}