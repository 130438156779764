
.verticalPadding {
    padding-top: 10px;
    padding-bottom: 10px;
}
.horizontalPadding {
    padding-left: 40px;
    padding-right: 40px;
}

.spacer {
    height: 1px;
    border-bottom: 1px solid #E3F1EE;
}

.horizontalPaddingSmall {
    padding-left: 15px;
    padding-right: 15px;
}
.btnsWrapper {
    flex-basis: 100%;
    flex-grow: 0;
    cursor: pointer;
}
.btnsWrapperBorder {
    border-right: 1px solid #E3F1EE;
}
.sendBtnContainer {
    border: 1px solid #FFCB4D;
    border-radius: 50%;
    min-width: 40px;
    height: 100%;
    background-color: white;
}
.chatWrapper {
    flex-basis: 0px;
    flex-grow: 1;
    overflow: auto;
}
.initialsContainer {
    padding: 7px;
    border-radius: 50%;
    background: rgba(227, 237, 235, 0.14);
    border: 1px solid #E3F1EE;
}
.messageWrapper {
    background: rgba(227, 237, 235, 0.14);
    border-radius: 6px;
    border: 1px solid #E3F1EE;
    padding: 10px;
}
.messageWrapperLeft {
    border-top-left-radius: 0px;
}
.messageWrapperRight {
    border-top-right-radius: 0px;
}
.activeSectionTitle {
    color: #008553;
    font-weight: bold;
}
.inactiveSectionTitle {
    color: #616161;
    opacity: 0.6
}
