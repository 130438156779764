.userContainer {
  padding: 11px 25px 12px 18px;
}
.userContainer:hover {
  background-color: var(--new-border-gray);
}
.userContainer:hover .editBtnContainer {
  visibility: visible;
}
.userContainer .editBtnContainer {
  visibility: hidden;
}
