.button {
    text-align: center;
    outline: none;
    border-style: none;
    font-weight: bold;

}
.padding {
    padding: 10px 30px;
}
.buttonSuccess {
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;

}
.buttonPrimary {
    border-radius: 6px;
    border: 0.5px solid #f0e6e6;
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);

}
.buttonPrimary:hover {
    background: var(--background-grey);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}
.buttonSecondary {
    background-color: var(--gray);
    color: var(--white);
    border-radius: 30px;
    border-style: none;
}
.buttonLarge {
    height: 56px;
}
.button-medium {
    height: 48px;
}
.buttonSmall {
    height: 39px;
}
.button:disabled {
    background: var(--new-feedback-inactive);
    pointer-events: none;
}
.buttonBlank {
    background-color: white;
}
.buttonNeutral {
    background-color: var(--new-feedback-neutral);
    color: white
}
.buttonNegative {
    background-color: var(--new-feedback-negative);
    color: white
}
.buttonPositive {
    background-color: var(--new-feedback-positive);
    color: white;
}
.shapeRounded {
    border-radius: 30px;
}
.shapeSemiRounded {
    border-radius: 6px;
}
.fillWidth {
    width: 100%;
}
