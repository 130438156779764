/*noinspection ALL*/
.inputContainer {
    width: 100%;
    position: relative;
    border: 1px solid #EAEAEF;
    box-sizing: border-box;
    overflow: clip;
}
.inputContainer input {
    width: 100%;
    height: 100%;
    margin-bottom: 4px;
    padding: 0px 20px;
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--primary-color-dark);

    outline: none;
    border: none;
}
.inputContainer.withLabel input {
    padding: 15px 20px 0px;
}
.inputBorderError {
    border-color: var(--color-red) !important;
}
.inputDisabled {
    background-color: var(--new-feedback-inactive);
    /*background-color: rgba(201, 201, 201, 0.55);*/
}

.errorInputContainer {
    margin-top: 5px;
    min-height: 20px;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
}
.loginErrorMessage {
    color: var(--color-red);
}
.label {
    position: absolute;
    left: 20px;
    top: 0px;
    color: rgba(50, 62, 71, 0.4);
}
.inputSmall {
    height: 30px;
}
.inputMedium {
    height: 45px;
}
.inputLarge {
    height: 55px;
}
.inputRounded {
    border-radius: 66px;
}
.inputSemiRounded {
    border-radius: 8px;
}
