table {
  width: 100% ;
  table-layout: fixed;
}
.tableHeader th {
  background: #EDF3F2;
  border-top: 1px solid #EAEAEF;
  border-left: none;
  border-right: none;
  color: #008553;

}
table tr td, table tr th {
  padding-left: 8px;
  padding-right: 8px;
}
table thead {
  position: sticky;
  top: 0;
  z-index: 2;
}
