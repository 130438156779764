.admin-header {
  /*width: 100%;*/
  display: flex;
  background: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(151,151,151,0.2);
}
.logo-container {
  padding: 16px 0 16px 90px;
}
.dropdown-parent {
  position: relative;
}
.dropdown {
  position: absolute;
  top: calc(100% + 10px);
  padding: 8px 18px;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.14);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  animation: showing-up 150ms ease-in;
  width: 100%;
}


