.timeColumn {
  flex-basis: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.timeLabel {
  color: green;
}
.iconsRow {
  gap: 5px
}

.selectedShiftCard {
  outline: solid 3px rgba(187, 107, 217, 1);
}
