.checkbox_group {
  display: flex;
  flex-direction: column;
}
.quotaCheckbox {
  width: 75%;
  flex-shrink: 0;
}
.quotaInput {
  width: 25%;
  flex-shrink: 0;
}