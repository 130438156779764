.link {
    cursor: pointer;
    text-decoration: none;
}
.primary {
    color: var(--primary);
}
.secondary {
    color: var(--primary-color-dark);
}
.primary:hover {
    color: var(--primary-fade)
}
