.section {
  /*height:100%;*/
  display:flex;
  justify-content:center;
  align-items:start;
  padding-top: 60px;
  flex-grow: 1;
}

.box {
  max-width: 440px;
  /*box-shadow:0 8px 24px 0 rgba(0,0,0,0.1),0 16px 64px 0 rgba(22,26,26,0.1);*/
  flex-grow: 1;
  margin: 0 1.5rem;
}
.login-footer {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.footer-link {
  cursor: pointer;
  color: var(--primary-color-dark);
}
