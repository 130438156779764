.columnsWrapper {
    display: flex;
    flex-grow: 1;
}
.listColumn {
    flex-basis: 30%;
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid var(--new-border-gray);
    padding-top: 30px;
    padding-left: 22px;
    padding-right: 30px;
}
.listContainer {
    padding-top: 25px;
}

.titleContainer {
    padding-left: 18px;
    gap: 40px;
}
