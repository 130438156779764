.headerBar {
  gap: 20px;
}
.tableContainer {
  border: 1px solid #E3F1EE;
  border-radius: 8px;
  flex-grow: 1;
  padding: 10px;
}
.tableCell {
  padding-top: 20px;
}
.tableHeader {
  padding-bottom: 10px;
  vertical-align: bottom;
}
.editActionsContainer {
  border: 1px solid #F7C345;
  border-radius: 6px;
  padding: 20px 10px;
}
.dateHeader {
  padding-top: 10px;
  padding-bottom: 10px;
}
.editAction {
  cursor: pointer;
  padding: 0px 15px;
}
.editAction:hover {
  background-color: var(--new-border-gray);
}
