.errorContainer {
    z-index: 200;
    background-color: red;
    color: white;
    position: fixed;
    top: 5px;
    left: 10px;
    right: 10px;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
}
