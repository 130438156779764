.bordered {
    border: 1px solid var(--new-brand-1);
    border-radius: 8px;
    padding: 20px 40px;
}
.widthSmall {
    max-width: 500px;
}
.widthLarge {
    max-width: 1000px;
}
