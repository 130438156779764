.headerRow {
    height: 55px;
    padding: 0 20px;
    border-bottom: 1px solid #E3F1EE;
}
.headerGroup {
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;
    gap: 20px;
}
.timeFilterContainer {
    background: rgba(227, 237, 235, 0.66);
    border-radius: 6px;
    color: #929292;
    cursor: pointer;
}
.timeFilterLeft {
    padding: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.timeFilterCenter {
    padding: 5px;
}
.timeFilterRight {
    padding: 5px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.activeTimeFilter {
    color: white;
    background-color: #18C671;
}
