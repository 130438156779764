.dayHeading {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.dayHeading > p {
  flex-grow: 1;
}
.dayLetter {
  color: #828282;
  padding-top: 5px;
}
.sideBar {
  width: 40px;
}
.arrowContainer {
  flex-grow: 1;
  text-align: center;
  border: 1px solid #E3F1EE;
  padding-top: 5px;
}
.weekHeader {
  border-bottom: 1px solid #00B471;
  height: 55px;
}
.flexChild {
  flex-basis: 100%;
}
.dayTile {
  border: 1px solid lightgray;
}
