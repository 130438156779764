
.loginForm {
    width: 100%;
    gap: 20px
}
.emailLabel {
    margin-bottom: 6px;
    color: var(--primary-color);
    font-size: var(--font-size-small);
    font-weight: 600;
    line-height: 15px;
}
.loginOptionsContainer {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
}
