
.weekHeader {
  border-bottom: 1px solid #00B471;
  height: 55px;
}

.sideBar {
  width: 40px;
}

.arrowContainer {
  flex-grow: 1;
  text-align: center;
  border: 1px solid #E3F1EE;
  padding-top: 5px;
}
.dayHeading {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  padding-top: 15px;
}
.dayHeading > p {
  flex-grow: 1;
}
