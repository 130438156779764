.resourcesPanelContainer {
  border: 1px solid #F0F7F6;
  border-radius: 8px;
}
.resourcesPanelSubGroupTop {
  padding: 20px 15px 0px;
}
.resourcesPanelSubGroupBottom {
  padding: 0px 15px 20px;
  gap: 10px;
}
.scrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
