.navColumn {
    flex-basis: 15%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 230px;
    padding: 0px 35px;
    border-right: 1px solid var(--new-border-gray);
}
.sideBar {
    padding: 30px 35px;
    gap: 30px
}
.newLogoContainer {
    height: 100%;
}
.contentRow {
    height: 100%;
}
.headerLink {
    opacity: 0.44;
    cursor: pointer;
    border-bottom: 6px solid transparent;
}
.headerLinkActive {
    border-bottom: 6px solid var(--new-brand-1);
    opacity: 1;
}
.sidebarLink {
    cursor: pointer;
}
.headerContainerRight {
    padding-left: 15px;
    padding-right: 30px;
}
.searchIcon {
    /*position: absolute;*/
    left: 10px;
    top: 4px;
}
.clickableIcon {
    cursor: pointer;
}
.searchContainer {
    padding-left: 10px;
}
.headerContainerLeft {
    gap: 40px;
    padding-left: 40px;
    align-items: stretch;
}
