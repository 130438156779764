.checkbox-container {
    cursor: pointer;
}
.checkbox-container input:checked {
    background-color: var(--primary);
    filter: hue-rotate(240deg);
}
.checkbox-label-text {
    cursor: pointer;
    color: var(--primary-color-dark);
    margin-left: 0.125rem;
}
