.page-wrapper {
    margin: 2rem auto;
    padding: 1.625rem 2.5rem 1.375rem;
    border: 1px solid #EAEAEF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    max-width: 90%;
}
.page-wrapper-fill-width {
    min-width: 90%;
}
