:root {
  --new-text-black: #000000;
  --new-text-gray: #333333;
  --new-border-gray: #E3F1EE;

  --new-brand-1: #18c671;
  --new-brand-2: #00B471;
  --new-brand-3: #323E47;

  --new-brand-1-text: #008553;

  --new-feedback-positive: rgba(24, 198, 113, 0.7);
  --new-feedback-neutral: rgba(255, 180, 0, 0.7);
  --new-feedback-negative: rgba(196, 23, 60, 0.7);
  --new-feedback-inactive: rgba(50, 62, 71, 0.25);

  --new-font-100: 11px;
  --new-line-height-100: 13px;
  --new-font-200: 12px;
  --new-line-height-200: 14px;
  --new-font-300: 13px;
  --new-line-height-300: 16px;
  --new-font-400: 16px;
  --new-line-height-400: 19px;
  --new-font-500: 18px;
  --new-line-height-500: 22px;
  --new-font-600: 21px;
  --new-line-height-600: 25px;
  --primary-color: #323E47;
  --primary-color-dark: #656565;
  --primary: #18C671;
  --primary-inactive: rgba(50, 62, 71, 0.25);
  --primary-fade: rgba(24, 198, 113, 0.5);
  --color-red: #C4173C;
  --outlaw-grass: #00B471;
  --outlaw-grass-fade: rgba(0, 180, 113, 0.3);
  --font-size-large: 20px;
  --font-size: 16px;
  --font-size-small: 14px;
  --line-height-large: 30px;
  --line-height: 24px;
  --line-height-small: 20px;
  --background-grey: #F7F8F9
}

body {
  margin: 0;
  font-family: "Lato", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px;
  width: 100vw;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  box-sizing: border-box;
  font-size: var(--font-size);
  line-height: var(--line-height);
  overflow-x: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
}
input {
  filter: none;
}

input[type="checkbox"] {
  cursor: pointer;
}
.small-text {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}
.text-100 {
  font-size: var(--new-font-100);
  line-height: var(--new-line-height-100);
}

.text-200 {
  font-size: var(--new-font-200);
  line-height: var(--new-line-height-200);
}

.text-300 {
  font-size: var(--new-font-300);
  line-height: var(--new-line-height-300);
}

.text-400 {
  font-size: var(--new-font-400);
  line-height: var(--new-line-height-400);
}

.text-500 {
  font-size: var(--new-font-500);
  line-height: var(--new-line-height-500);
}

.text-600 {
  font-size: var(--new-font-600);
  line-height: var(--new-line-height-600);
}
.spacer-100 {
  width: 10px;
  display: inline-block;
}
.fill-height {
  height: 100%;
}

/* GOOGLE PLACES*/

.pac-container {
  padding: 0 14px;
  border-radius: 16px;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.14);
}
.pac-container:after {
   background-image: none;
   height: 0;
 }

.pac-item {
  font-size: 13px;
  line-height: 36px;
  cursor: pointer;
  border: none;
}

.pac-item:hover {
  color: #18C671;
  background: none;
}
.pac-matched {
  font-weight: 400;
  color: #18C671;
}

.pac-item-query {
  font-weight: 400;
  color: #18C671;
}

.pac-icon {
  display: none;
}
