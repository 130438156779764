.resetShiftDialog {
    border-radius: 8px !important;
    padding: 20px;
}
.verticalContainer {
    margin-top: 20px;
    padding-left: 40px;
}
.standardTimesContainer {
    border: 1px solid #E3F1EE;
    border-radius: 8px;
    padding: 20px 15px;
    margin-top: 10px;
    gap: 30px;
}
.standardTimesRow {
    gap: 50px;
    margin-bottom: 20px;
}
