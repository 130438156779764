.loginHeader {
    padding: 0 1.5rem 0.25rem 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #FFFFFF;
}
.loginHeader > img {
    max-width: 45%;
}
/*avatar*/
.avatarContainer {
    background: linear-gradient(180deg, #18C671 0%, #00B471 100%);
    border-radius: 50%;
    color: var(--white);
    padding: 3px 5px;
}
.headerRow {
    height: 70px;
    border-bottom: 1px solid var(--new-border-gray);
}
.operatorOverviewStyle {
    background-color: rgba(187,255,44,0.63);
    gap: 10px
}
.operatorNameLabel {
    color: #c000c7;
}
