
.hourCell {
    height: 40px;
    border-bottom: 1px solid rgba(227, 241, 238, 0.55);
    display: flex;
    padding: 0px 5px;
    /*position: relative;*/
}
.laneCell {
    width: 100%;
    position: relative;
}
.todayColumn {
    background: #E9F8F2;
}

.hourMark {
    height: 40px;
    color: #828282;
}
